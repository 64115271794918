<template>
  <div class="sg-web-content-2">
    <img src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/star.png" class="sg-w100" />
    <el-row class="sg-border-bottom">
      <el-col :sm="{ span: 16, offset: 4 }">
        <div class="sg-flex-center">
          <div
            class="sg-space-between sg-bold sg-gray-b1 sg-font-14px"
            style="width: 1152px"
          >
            <div class="sg-flex-center">
              <i class="el-icon-s-home sg-orange"></i>
              <i class="el-icon-caret-right sg-pad" style="padding: 0 11px"></i>
              <div>关于正启之星</div>
              <i class="el-icon-caret-right sg-pad" style="padding: 0 11px"></i>
              <div>人力资源</div>
            </div>
            <div class="sg-flex-center">
              <div
                class="sg-tab sg-pointer sg-selected  sg-nav-ani sg-gray-6a"
                :style="{'margin-left': '30px', 'line-height': scrollNavDown ? '80px' : '110px', 'height': scrollNavDown ? '82px' : '112px'}"
              >
                人才招聘
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div>
      <!-- <el-row>
        <el-col :sm="{ span: 18, offset: 3 }">
          <div class="sg-pad-md sg-gray-cbc4c0 sg-font-18px sg-border-bottom">
            <div>人才招聘</div>
            <div>TALENT RECRUITMENT</div>
          </div>
        </el-col>
      </el-row> -->
      <div>
        <transition name="sg-fade-in-0p2" appear>
          <el-row>
            <el-col :sm="{ span: 14, offset: 5 }">
              <div class="sg-flex-center">
                <img
                  src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/hire-3.jpg"
                  class="sg-w100"
                  style="
                    margin-top: 63px;
                    z-index: 100;
                    position: relative;
                    width: 1040px;
                  "
                />
              </div>
            </el-col>
          </el-row>
        </transition>

        <transition name="sg-fade-in-0p2" appear>
          <div
            class="sg-gray-6-bg"
            style="
              margin-bottom: 68px;
              margin-top: -50px;
              z-index: -1;
              position: relative;
            "
          >
            <el-row>
              <el-col :sm="{ span: 14, offset: 5 }">
                <div class="sg-flex-center">
                  <transition name="sg-fade-up-0p4" appear>
                    <div style="width: 1040px">
                      <img
                        src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/quote-left.png"
                        class="sg-font-icon sg-h100"
                        style="padding-top: 26px"
                      />
                      <div
                        class="sg-red sg-font-24px"
                        style="padding: 15px 0 17px 0"
                      >
                        人才是最大的资本
                      </div>
                      <div
                        class="sg-gray-41 sg-font-12px"
                        style="line-height: 20px"
                      >
                        <div>
                          在企业发展的道路上，正启之星始终倡导诚信、高效、开放的企业文化，积极营造使人才脱颖而出的氛围，努力打造学习型的组织和富有战斗力的团队。
                        </div>

                        <div style="margin-top: 12px">
                          我们尊重人才，珍惜人才，通过提供良好的工作环境、具有挑战性的工作以及有竞争力的薪酬，为每一位员工构建展现才华的舞台，使员工的人力资本得到保值与增值，使员工与企业共同成长。
                        </div>

                        <div style="margin-top: 12px">
                          在十多年发展中，我们始终坚持核心队伍与专业人才的培养与建设，锻造出了一支具有专业素养、开阔视野和拥有持续创新、学习能力的强大核心团队。同时，越来越多的优秀人才也正在源源不断地加入正启之星，和我们一起努力创造，携手前行。
                        </div>

                        <div style="margin-top: 12px">
                          伴随企业的快速发展和市场的不断扩大，面向未来，我们需要更多充满活力、永怀理想、
                          努力奋斗、追求卓越的专业人才加入我们。如果你是这样的人，如果你想成为我们的一员，欢迎联系我们。
                        </div>
                      </div>
                      <div
                        class="sg-orange-2 sg-font-13px"
                        style="line-height: 20px; margin-top: 12px"
                      >
                        我们会认真对待每一份投递，不错过每一个优秀的你。
                      </div>
                      <img
                        src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/quote-right.png"
                        class="sg-font-icon sg-h100 sg-float-right"
                        style="padding-bottom: 30px"
                      />
                    </div>
                  </transition>
                </div>
              </el-col>
            </el-row>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Tempalte",
  props: {},
  data: () => {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState({
      scrollNavDown: (state) => state.scrollNavDown,
    }),
  },
  mounted() {
    this.show = true;
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
.sg-tab.sg-selected {
  color: $red;
  border-bottom: 2px solid $red;
}
.sg-index-line {
  width: 30px;
  height: 3px;
  background: $red;
}
.sg-red-border-right {
  border-right: 2px solid $red;
}
.sg-desc {
  color: gray;
  line-height: 1.6em;
}
.sg-dash-border {
  border-top: 1px dashed gray;
}
.sg-hover-gray:hover {
  background: $gray-4;
}
.sg-core-bg {
  background-image: url("https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/core-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.sg-core-txt-box {
  width: 300px;
  position: absolute;
  right: 0;
  top: 40%;
}
.sg-img-right {
  width: 300px;
}
.sg-core-bg {
  background-image: url("https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/industry-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.sg-industry-box {
  padding: 100px 0;
}
</style>
